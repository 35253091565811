import React from "react";
import { Divider } from "antd";
// Definición de estilos en JS
const styles = {
  MovingText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "relative",
  },
  scrollingText: {
    color: "black",
    // position: "absolute",
    whiteSpace: "nowrap",
    animation: "scroll 20s linear infinite",
  },
  // La definición de keyframes directamente en un objeto de estilo no es posible,
  // por lo que los keyframes se deben incluir en un archivo CSS global o en un <style> en el componente.
};

const MovingText = () => {
  return (
    <>
      <style>
        {`
            @keyframes scroll {
              0% {
                transform: translateX(100%);
              }
              100% {
                transform: translateX(-100%);
              }
            }
          `}
      </style>
      <div style={styles.MovingText}>
        <div style={styles.scrollingText}>
          Horario bolsa argentina: lunes a viernes de 10:00 a 17:00 hs. Horario
          bolsa NYSE: lunes a viernes de 9:30 a 16:00 hs (hora USA) Informacion
          actualizada cada 20 mins dentro del horario bursátil
        </div>
      </div>
    </>
  );
};

export default MovingText;
