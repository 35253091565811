import React, { createContext, useContext, useState, useEffect } from "react";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [dataSoket, setData] = useState([]);

  useEffect(() => {
    /*  const ws = new WebSocket("ws://www.contadoconliqui.com/ws"); */
    const ws = new WebSocket("ws://localhost:8080");
    ws.onmessage = (event) => {
      let message = null;
      try {
        message = JSON.parse(event.data);
      } catch (error) {}

      setData((prevData) => message);
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ dataSoket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
