import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Card } from "antd";
import WideCarousel from "./components/WideCarousel";
import MyTable from "./components/MyTable";
import "./App.css";
import useMediaQuery from "./Hooks/useMediaQuery";
import { WebSocketProvider } from "./WebSocketContext";
import MovingText from "./components/MovingText";
const { Title, Paragraph } = Typography;
const cardStyle = { backgroundColor: "#34c5b2" };
const socket = new WebSocket("ws://www.contadoconliqui.com:8080");
//const baseUrl = "http://192.168.100.3:3000/api";
const baseUrl = "http://www.contadoconliqui.com/api";
const arbitrageVariation = 20;
function App() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const fontSize = isMobile ? 3 : 1;
  const [data, setData] = useState("");
  const [average, setAverage] = useState("");
  useEffect(() => {
    socket.onopen = function (event) {
      console.log("Conexión establecida");
    };
    const cargarDatos = async () => {
      try {
        const respuesta = await fetch(baseUrl + "/avg");
        const data = await respuesta.json();
        console.log("data", data);
        setAverage(data.avg);
      } catch (error) {
        console.error("Error al cargar los datos de la API:", error);
      }
    };
    socket.onmessage = function (event) {
      try {
        const dataParsed = JSON.parse(event.data);
        console.log("Mensaje recibido:", dataParsed);
        setData(dataParsed);
      } catch (error) {
        console.log("Error al parsear el mensaje:", error);
      }

      // Aquí puedes manejar los mensajes entrantes
    };

    socket.onerror = function (event) {
      console.error("Error en WebSocket detectado:", event);
    };

    socket.onclose = function (event) {
      console.log("Conexión cerrada");
    };
    cargarDatos();
  }, []);

  return (
    <WebSocketProvider>
      <div className="App">
        {/*         <Row justify="center" align="top" style={{ minHeight: "100vh" }}>
          <Col className="hero" xs={22} sm={22} md={22} lg={22} xl={22}>
            <div>
              <Title level={fontSize} className="berberecho">
                Bienvenidos a contadoconliqui.com
              </Title>
            </div>
          </Col> */}
        {!isMobile && (
          <div>
            <Row></Row>
            <Row justify="center" align="top" style={{ minHeight: "100vh" }}>
              <Col className="hero" xs={22} sm={22} md={22} lg={22} xl={22}>
                <Title level={fontSize} className="berberecho">
                  Bienvenidos a contadoconliqui.com
                </Title>
                <MovingText />
                <Row justify="center" align="top">
                  <Col md={12}>
                    <Title level={1} style={{ textAlign: "center" }}>
                      CCL : {average ? Math.round(average) : "Cargando..."}
                    </Title>
                  </Col>
                </Row>
              </Col>

              <Col md={12}>
                <MyTable title={"Orden: volumen"} path={"/find"} />{" "}
              </Col>
              <Col md={12}>
                <MyTable
                  title={"Orden: ccl más caro"}
                  path={"/most_expensive"}
                />{" "}
              </Col>
            </Row>

            <Row justify="center" align="top" style="">
              <Col md={24}>
                <MyTable
                  title={`posibles arbitrajes -  variación +/- ${arbitrageVariation} % `}
                  path={`/possible_arbitration`}
                  query={`cota=${arbitrageVariation}`}
                />{" "}
              </Col>
            </Row>
          </div>
        )}

        {isMobile && (
          <div>
            <Row justify="center" align="top" style={{ minHeight: "100vh" }}>
              <Col className="hero" xs={22} sm={22} md={22} lg={22} xl={22}>
                <div>
                  <Title level={fontSize} className="berberecho">
                    Bienvenidos a contadoconliqui.com
                  </Title>
                  <MovingText />
                </div>
              </Col>
              <Row justify="center" align="top">
                <Col>
                  <Title level={1} style={{ textAlign: "left" }}>
                    CCL : {average ? Math.round(average) : "Cargando..."}
                  </Title>
                </Col>
              </Row>

              <Col xs={24} sm={24} md={8}>
                <WideCarousel
                  title={"10 con más volumen"}
                  path={"/find"}
                  innerText={
                    "Podrás consultar en tiempo real la cotización del CCL por cedear"
                  }
                  data={data}
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <WideCarousel
                  title={"10 con ccl más caro"}
                  path={"/most_expensive"}
                  innerText={
                    "Podrás consultar en tiempo real la cotización del CCL por cedear"
                  }
                  data={data}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </WebSocketProvider>
  );
}

export default App;
