import { Carousel, Modal, Descriptions, Badge } from "antd";
import React, { useState, useEffect } from "react";
import CardCustom from "./Card";
import { useWebSocket } from "../WebSocketContext";
const baseUrl = "http://www.contadoconliqui.com/api";
//const baseUrl = "http://localhost:3000/api";
//const baseUrl = "http://192.168.100.3:3000/api";
const financeDataProviders = ["https://finance.yahoo.com/quote/"];
const cardStyle = {
  backgroundColor: "#34c5b2",
  marginRight: "15px", // Espacio entre elementos del carrusel
  display: "inline-block",
};
function WideCarousel(props) {
  const { dataSoket } = useWebSocket();
  let [data, setData] = useState(null);
  const [modalData, setModalData] = useState({ record: null, url: "" });
  const [soketData, setsoketData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = (record) => {
    setModalData({ record });
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleRowClick = (record) => {
    console.log("Registro seleccionado:", record);
    showModal(record);

    // Redireccionar a la URL guardada en el campo 'url' del registro
    //  window.location.href = record.url;
  };
  const redirect = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    setsoketData(dataSoket);
  }, [dataSoket]);

  useEffect(() => {
    if (data && soketData) {
      const newData = data.selection.map((item) => {
        if (item.tiker === soketData.tiker) {
          console.log("pase el if");
          let compra = soketData.ccl_compra ?? item.ccl_compra;
          let venta = soketData.ccl_venta ?? item.ccl_venta;
          item.ccl_compra = Math.round(compra);
          item.ccl_venta = Math.round(venta);
        }
        return item;
      });
      console.log("newData", newData);
      if (newData !== data) {
        setData(newData);
      }
      //  setData(data);
    }
  }, [soketData]);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const respuesta = await fetch(baseUrl + props.path);
        const data = await respuesta.json();
        setData(data);
      } catch (error) {
        console.error("Error al cargar los datos de la API:", error);
      }
    };
    console.log("RENDER");

    cargarDatos();
  }, []);
  return (
    <div>
      <h2 style={{ marginLeft: "10px" }}> {props?.title} </h2>
      <Carousel
        slidesToShow={2}
        dots={false}
        swipeToSlide={true}
        draggable={true}
      >
        {data &&
          data.selection.map((item) => (
            <div style={{ marginLeft: "10px" }}>
              <CardCustom
                onClickFunction={showModal}
                clickArgument={item}
                style={cardStyle}
                className="card-background"
                title={item.tiker}
                innerText={
                  Math.round(item.ccl_compra) +
                  "  " +
                  Math.round(item.ccl_venta)
                }
              />
            </div>
          ))}
      </Carousel>
      <div>
        <Modal
          title={modalData?.record?.tiker + " - " + modalData?.record?.name}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800} // Ajusta el ancho según tus necesidades
        >
          {modalData?.record?.tiker && (
            <div>
              <Descriptions title="">
                <Descriptions.Item label="CEDEAR precio">
                  {modalData?.record?.precio + " ARS"}
                </Descriptions.Item>
                <Descriptions.Item label="NYSE precio">
                  {modalData?.record?.usd_price + " USD"}
                </Descriptions.Item>
                <Descriptions.Item label="Ratio">
                  {modalData?.record?.ratio_left}:
                  {modalData?.record?.ratio_right}
                </Descriptions.Item>
                {}
                <Descriptions.Item label="">
                  <Badge
                    className="mi-badge"
                    count="Yahoo Finance"
                    showZero
                    color="#a924dd"
                    onClick={() =>
                      redirect(
                        financeDataProviders[0] + modalData?.record?.tiker
                      )
                    }
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default WideCarousel;
