// Card.js
import { Card } from "antd";
import React from "react";
const cardStyle = {
  backgroundColor: "#34c5b2",
  color: "white",
  fontSize: "20px",
  marginRight: "5px",
  marginLeft: "5px", // Espacio entre elementos del carrusel
  // display: "inline-block",
};
function CardCustom({ title, innerText, onClickFunction, clickArgument }) {
  return (
    <div>
      <Card
        style={cardStyle}
        onClick={() => onClickFunction(clickArgument)}
        className="card-background"
        title={title}
      >
        {innerText}
      </Card>
    </div>
  );
}

export default CardCustom;
