import React, { useState, useEffect } from "react";
import { Table, Modal, Descriptions, Badge } from "antd";
import { useWebSocket } from "../WebSocketContext";

import { format, isToday, formatDistance, subDays } from "date-fns";
import CardCustom from "./Card";
import "./tableStyle.css";
import "./badgeStyle.css";
const baseUrl = "http://www.contadoconliqui.com/api";
//const baseUrl = "http://localhost:3000/api";
//const baseUrl = "http://192.168.100.3:3000/api";
const financeDataProviders = ["https://finance.yahoo.com/quote/"];

const columns = [
  {
    title: "Tiker",
    dataIndex: "tiker",
    key: "tiker",
  },
  {
    title: "Ratio",
    key: "ratio",
    render: (text, record) => `${record.ratio_left}:${record.ratio_right}`,
  },
  {
    title: "CCL Compra",
    dataIndex: "ccl_compra",
    key: "ccl_compra",
  },
  {
    title: "CCL Venta",
    dataIndex: "ccl_venta",
    key: "ccl_venta",
  },

  {
    title: "Volumen ARGS",
    dataIndex: "trade_volume",
    render: (text, record) => `${record.trade_volume.toLocaleString("es-AR")}`,
  },
  {
    title: "Actualizado",
    dataIndex: "last_update",
    render: (text, record) => `${formatUnixDate(record.timestamp)}`,
  },
  // Añade más columnas según sea necesario
];
function formatUnixDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convertir Unix Timestamp a objeto Date
  if (isToday(date)) {
    // Si la fecha es hoy, mostrar "hoy a las HH:MM"
    return `hoy a las ${format(date, "HH:mm")}`;
  } else {
    // Si no es hoy, mostrar "hace X tiempo"
    return formatDistance(date, new Date(), {
      addSuffix: true,
      includeSeconds: true,
    });
  }
}
const MyTable = (props) => {
  const redirect = (url) => {
    window.open(url, "_blank");
  };
  const handleRowClick = (record) => {
    console.log("Registro seleccionado:", record);
    showModal(record);

    // Redireccionar a la URL guardada en el campo 'url' del registro
    //  window.location.href = record.url;
  };
  const handleTableChange = (pagination) => {
    console.log("pagination", total);
    setCurrentPage(pagination.current);
    setPageSize(10);
    cargarDatos(10, (pagination.current - 1) * 10);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [modalData, setModalData] = useState({ record: null, url: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const { dataSoket } = useWebSocket();
  let [data, setData] = useState(null);
  const [soketData, setsoketData] = useState(null);
  const showModal = (record) => {
    setModalData({ record });
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setsoketData(dataSoket);
  }, [dataSoket]);

  useEffect(() => {
    if (data && soketData) {
      const newData = data.map((item) => {
        item.ccl_compra = Math.round(item.ccl_compra);
        item.ccl_venta = Math.round(item.ccl_venta);
        if (item.tiker === soketData.tiker) {
          let compra = soketData.ccl_compra ?? item.ccl_compra;
          let venta = soketData.ccl_venta ?? item.ccl_venta;
          item.ccl_compra = Math.round(compra);
          item.ccl_venta = Math.round(venta);
        }

        return item;
      });
      console.log("newData", newData);
      if (newData !== data) {
        setData(newData);
      }
      //  setData(data);
    }
  }, [soketData]);
  const cargarDatos = async (limit, offset) => {
    try {
      const respuesta = await fetch(
        baseUrl +
          props.path +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          `${props?.query ? "&" + props.query : ""}`
      );
      const data = await respuesta.json();
      setData(
        data.selection.map((item) => ({
          ...item,
          ccl_compra: Math.round(item.ccl_compra),
          ccl_venta: Math.round(item.ccl_venta),
        }))
      );
      console.log("data", data);
      if (data?.selectionTotal?.total) {
        setTotal(data?.selectionTotal?.total);
      }
    } catch (error) {
      console.error("Error al cargar los datos de la API:", error);
    }
  };
  useEffect(() => {
    console.log("RENDER");

    cargarDatos(10, 0);
  }, []);
  return (
    <div>
      {data && (
        <div>
          <h2 style={{ marginLeft: "10px" }}> {props?.title} </h2>
          <Table
            dataSource={data}
            columns={columns}
            className="mi-tabla-antd"
            pagination={{
              current: currentPage,
              pageSize: 10,
              total: total,
              showSizeChanger: false,
            }}
            onChange={handleTableChange}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleRowClick(record);
                }, // Agregar el manejador de clicks aquí
              };
            }}
          />
          <Modal
            title={modalData?.record?.tiker + " - " + modalData?.record?.name}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800} // Ajusta el ancho según tus necesidades
          >
            {modalData?.record?.tiker && (
              <div>
                <Descriptions title="">
                  <Descriptions.Item label="CEDEAR precio">
                    {modalData?.record?.precio}
                  </Descriptions.Item>
                  <Descriptions.Item label="NYSE precio">
                    {modalData?.record?.usd_price}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ratio">
                    {modalData?.record?.ratio_left}:
                    {modalData?.record?.ratio_right}
                  </Descriptions.Item>
                  {}
                  <Descriptions.Item label="">
                    <Badge
                      className="mi-badge"
                      count="Yahoo Finance"
                      showZero
                      color="#a924dd"
                      onClick={() =>
                        redirect(
                          financeDataProviders[0] + modalData?.record?.tiker
                        )
                      }
                    />
                  </Descriptions.Item>
                </Descriptions>
              </div>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default MyTable;
